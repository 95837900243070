<script>
import Layout from "../../layouts/main";
import StudentLayout from '../../layouts/studentLayout';
import moment from 'moment';
import errorHandler from '../../../helpers/errorHandler';
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  name: "Studentattendance",
  page:{
    title: 'Student attendance'
  },
  components:{
    Layout,
    StudentLayout,
    ExportExcel
  },
  filters:{
    formatDate(value){
    if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
    }
    },
  },
  async created(){
    await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
        this.profileData = this.$store.getters['student/getStudent'];
    }).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('statistics/getStudentStat',this.$route.params.student).then(()=>{
        this.number = this.$store.getters['statistics/studentCount'];
    }).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch("balance/getPurchaseLogs",this.$route.params.student).then( ()=>{}).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    }); 
    },
    computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    logs() {
      return this.$store.getters["balance/allLogs"];
    },
    rows() {
      return this.logs.length;
    }
  },
  methods:{
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  data: () => ({
    profileData: null,    totalRows:0,
    recordId: null,
    title: "Purchase logs",
    items: [
      {
        text: "Manage",
        href: "/home"
      },
      {
        text: "Purchase",
        href: "/students"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "number",
    sortDesc: false,
    filterOn: ["number","name","description"],
    fields: [
      {
        key: "timestamp",
        label: "Time",
        sortable: true,
        selected: true
      },
      {
        key: "chapter",
        label: "Lecture",
        sortable: true,
        selected: true
      },
      {
        key: "purchase_type",
        label: "Attended",
        sortable: true,
        selected: true
      },
    ]
  })
};
</script>

<template>
  <Layout>
    <StudentLayout :student="profileData">
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                <export-excel :jsonData="logs" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="logs"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            <template v-slot:cell(chapter)="data">
              {{ data.item.chapter.name }}
            </template>
            <template v-slot:cell(timestamp)="data">
              {{ data.item.timestamp | formatDate }}
            </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </StudentLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
